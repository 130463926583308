import { memo, useState, useEffect, FC, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { Box, Dialog as MuiDialog, useTheme, Button, ButtonGroup, Typography } from '@mui/material';
import { Lock } from 'react-feather';

import { CanonicalContent, List, Rating as RatingType, Season, Share, useGetListsQuery } from '../../generated/graphql';
import { useToggleBookmark } from '../../hooks/bookmarks-context';
import { useIsInWatchlist, useIsWatched, useToggleInWatchlist, useToggleWatched } from '../../hooks/watch-state';
import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { useUpdateRating } from '../../hooks/use-update-rating';
import { ListChip } from '../ListChip';
import { AddCircle, AddCircleFilled } from '../icons';
import { Rating } from '../Rating';
import { Watched } from '../icons/Watched';
import { WatchedSeasonsDialog } from '../canonical/WatchedSeasonsDialog';


export type DialogProps = {
  share?: Pick<Share, '_id'> & {
    rating?: Pick<RatingType, 'rating'> | null,
    canonicalContent?: Pick<CanonicalContent, '_id'> | null,
  } | null;
  canonicalContent?: Pick<CanonicalContent, '_id' | 'type'> & {
    rating?: Pick<RatingType, 'rating'> | null
    tvshow?: { seasons: Pick<Season, 'name' | 'seasonNumber' | 'airDate'>[] } | null
  } | null;
  open: boolean;
  setOpen: (o: boolean) => void;
};


export const Dialog: FC<DialogProps> = memo(function Dialog({ share, canonicalContent, open, setOpen, ...rest }: DialogProps) {

  const theme = useTheme();
  const { track } = useAnalyticsQueued();

  const canonicalId = canonicalContent?._id || share?.canonicalContent?._id || undefined;

  const isWatched = useIsWatched(share?._id, canonicalId);
  const { toggleWatched, toggleWatchedSeasons } = useToggleWatched();
  const setIsWatched = (active: boolean, createFeedItems: boolean) => toggleWatched(canonicalId, share?._id, active, createFeedItems);
  const isInWatchlist = useIsInWatchlist(share?._id, canonicalId);
  const toggleInWatchlist = useToggleInWatchlist();
  const setIsInWatchlist = (active: boolean, createFeedItems=true) => toggleInWatchlist(canonicalId, share?._id, active, createFeedItems);

  const [ watchedSeasonsDialogOpen, setWatchedSeasonsDialogOpen ] = useState(false);

  const rating = canonicalContent?.rating?.rating || share?.rating?.rating || null;

  const updateRating = useUpdateRating(share?._id, canonicalId);

  const handleRatingChange = (e: SyntheticEvent, value: number | null) => {
    e.preventDefault();
    e.stopPropagation();
    if(value !== null && value !== -1 && value !== rating) {
      track('update_rating_dialog', { category: 'rating', value });
      if(!isWatched) {
        setIsWatched(true, false);
      }

      updateRating(value);
    }
  };

  const handleWatched = (event: SyntheticEvent, active: boolean, createFeedItems=true) => {
    event.preventDefault();
    event.stopPropagation();

    track('click_watched_button', { category: 'watch-state', origin: 'canonical' });
    // Show season popup if canonical is tvshow with multiple seasons
    const seasons = canonicalContent?.tvshow?.seasons;
    if(canonicalContent?.type === 'tvshow') {
      if(seasons && seasons.length === 1) {
        toggleWatchedSeasons(canonicalContent._id, [seasons[0].seasonNumber], active, createFeedItems);
      } else if (seasons && seasons.length > 1) {
        setWatchedSeasonsDialogOpen(true);
      } else {
        toggleWatched(canonicalId, share?._id, active, createFeedItems);
      }
    } else {
      toggleWatched(canonicalId, share?._id, active, createFeedItems);
    }
  }

  const handleWatchedSeasons = (event: SyntheticEvent, seasonNumbers: number[], active: boolean, createFeedItems=true) => {
    event.preventDefault();
    event.stopPropagation();
    if(!canonicalContent?.tvshow) {
      console.error("Can't toggle watched seasons without tvshow canonical", { canonicalId: canonicalId, shareId: share?._id, seasonNumbers, active });
      throw new Error(`Can't toggle watched seasons without tvshow canonical`);
    }
    track('click_watched_season', { category: 'watch-state', origin: 'canonical', canonicalId: canonicalContent._id, shareId: active, seasonNumbers: seasonNumbers.join(',') });
    toggleWatchedSeasons(canonicalContent._id, seasonNumbers, active, createFeedItems);
  }

  const { data } = useGetListsQuery();
  const lists = data?.currentUser?.lists as List[] | undefined;

  const [ listsWithBookmark, setListsWithBookmark ] = useState<List[]>([]);

  const toggleBookmark = useToggleBookmark();

  useEffect(() => {
    if(!lists || (!share && !canonicalContent)) {
      setListsWithBookmark([]);
    } else {
      const _listsWithBookmark = lists.filter((list) => {
        return list.name !== 'Archive' && list.items.some((item) => {
          if(share) {
            return item.share?._id === share._id;
          } else if(canonicalContent) {
            return item.canonicalContent?._id === canonicalContent._id;
          }
        })
      });
      setListsWithBookmark(_listsWithBookmark);
    }
  }, [ lists, share, canonicalContent ]);

  return (
    <MuiDialog
      PaperProps={{
        sx: { m: 2 }
      }}
      open={open}
      onClose={(ev: SyntheticEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
        setOpen(false);
      }}
      {...rest}
    >
      {canonicalContent?.tvshow?.seasons &&
        <WatchedSeasonsDialog canonicalId={canonicalContent._id} open={watchedSeasonsDialogOpen} onClose={() => setWatchedSeasonsDialogOpen(false)} handleWatchedSeason={handleWatchedSeasons} seasons={canonicalContent.tvshow.seasons} />
      }

      <Box sx={{ px: 2, py: 3 }} onClick={(ev: SyntheticEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
      }}>

        <ButtonGroup color="primary" sx={{ mb: 2 }}>

          <Button
            variant="contained"
            color={ isInWatchlist ? 'primary' : 'offWhite' }
            size="small"
            value="watchlist"
            disableRipple
            onClick={ () => setIsInWatchlist(!isInWatchlist) }
            startIcon={isInWatchlist ?
              (<AddCircleFilled fontSize="medium" />) :
              (<AddCircle fontSize="medium" sx={{ color: 'secondary', strokeWidth: 2 }} />)
            }
          >
            Watchlist
          </Button>

          <Button
            variant="contained"
            color={ isWatched ? 'primary' : 'offWhite' }
            size="small"
            value="watched"
            disableRipple
            onClick={ (ev) => handleWatched(ev, !isWatched, true) }
            startIcon={<Watched isWatched={isWatched} unselectedColor='offWhite.contrastText' selectedColor='white' />}
          >
            Seen
          </Button>

        </ButtonGroup>

        <Box sx={{ mt: 1, display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-evenly' }}>
          <Rating
            size='large'
            value={rating}
            onChange={handleRatingChange}
            sx={{ display: 'contents' }}
          />
          <Box sx={{ visibility: rating ? 'visible' : 'hidden' }}>
            {rating || 'x'}<Box component='span' sx={{ color: 'text.secondary' }}>/5</Box>
          </Box>
        </Box>

        <Typography variant="body1" sx={{ mt: 2 }}>
          Add to collection
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
            mt: 1,
            p: 1,
            backgroundColor: theme.palette.grey[200],
            borderRadius: '4px',
            overflow: 'auto',
            maxHeight: '200px'
          }}
        >
          {lists?.filter?.(l => l.name !== 'Archive' && l.name !== 'Uncategorized')?.map(list => {
            const inList = listsWithBookmark.some(l => l.name === list.name);
            return (
              <ListChip
                key={list._id}
                variant={ inList ? 'selected' : 'selectable' }
                list={list}
                icon={ !list.isPublic ? <Lock style={{ stroke: '#fff' }} /> : undefined }
                onClick={ () => {
                  console.log(share, canonicalContent, list, !inList);
                  toggleBookmark(share, canonicalContent, list, !inList);
                } }
                sx={{ mb: 1, mr: 1 }}
              />
            );
          })}
        </Box>
        <Typography variant="body2" sx={{ pt: 1 }}>
          <Link
            to="/bookmarks?create-collection-sheet=1"
            style={{ color: theme.palette.primary.main }}
            onClick={() => setOpen(false)}
          >
            Create a collection
          </Link>
        </Typography>
      </Box>
    </MuiDialog>
  );
});
