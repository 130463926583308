import { FC } from "react";
import { Box } from "@mui/material";

import { CanonicalContent, Movie, Provider as ProviderType, TvShow, Image as ImageType } from "../generated/graphql";
import { PosterCard, PosterCardProps } from "./PosterCard";
import { LazyRender } from "./LazyRender";


type PosterGridProps = {
  listItems: {
    canonicalContent?: Pick<CanonicalContent, '_id' | 'type'> & {
      movie?: Pick<Movie, 'title' | 'year'> & {
        poster?: Pick<ImageType, 'hash'> | null,
        providers?: {
          type: string,
          provider: Pick<ProviderType, '_id' | 'name'> & {
            logo?: Pick<ImageType, 'hash'> | null,
          },
        }[],
      } | null,
      tvshow?: Pick<TvShow, 'title' | 'startYear' | 'endYear'> & {
        poster?: Pick<ImageType, 'hash'> | null,
        providers?: {
          type: string,
          provider: Pick<ProviderType, '_id' | 'name'> & {
            logo?: Pick<ImageType, 'hash'> | null,
          },
        }[],
      } | null
    } | null,
    share?: PosterCardProps['share'],
  }[],
  showTitle?: boolean,
  scrollContainerRef?: React.RefObject<HTMLElement>,
  trackingCategory: string,
};


export const PosterGrid: FC<PosterGridProps> = function PosterGrid({ listItems, trackingCategory, showTitle=false, scrollContainerRef }) {

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))', gap: 1, maxWidth: '100%' }}>
      {listItems.map((listItem) => {
        const { canonicalContent, share } = listItem;

        const poster = canonicalContent?.[canonicalContent.type === 'movie' ? 'movie' : 'tvshow']?.poster;
        if(!poster) {
          return null;
        }

        return (
          <Box key={canonicalContent._id} sx={{ '& .lazyload-placeholder': { backgroundColor: 'grey.100' }, overflow: 'hidden' }}>
            <LazyRender
              height={205}
              offset={400}
              resize={true}
              unmountIfInvisible={true}
              overflow={true}
              scrollContainerRef={scrollContainerRef}
            >
              <Box sx={{ position: 'relative', borderRadius: '4px', overflow: 'hidden' }}>
                <PosterCard canonicalContent={canonicalContent} share={share} sx={{ width: undefined, height: undefined, borderRadius: 0 }} showTitle={showTitle} showProvider={true} showButtons={true} trackingCategory={trackingCategory} />
              </Box>
            </LazyRender>
          </Box>
        )
      })}
    </Box>
  );
}
