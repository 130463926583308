import { FC, ReactNode, useRef, useState } from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';


type TransitionState = 'visible' | 'hidden' | 'willAppear' | 'willDisappear';

const duration = 0.25;

const transitionClasses: Record<TransitionState, object> = {
  visible: {
    opacity: 1,
    pointerEvents: 'all',
    visibility: 'visible',
    contentVisibility: 'visible',
    transform: 'scaleX(1)',
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
    visibility: 'hidden',
    contentVisibility: 'hidden',
    transform: 'scaleX(0)',
  },
  willAppear: {
    opacity: 1,
    pointerEvents: 'none',
    visibility: 'visible',
    contentVisibility: 'visible',
    transform: 'scaleX(1)',
    transition: `opacity ${duration}s ease-in-out`,
  },
  willDisappear: {
    opacity: 0,
    pointerEvents: 'none',
    visibility: 'visible',
    contentVisibility: 'visible',
    transform: 'scaleX(1)',
    transition: `opacity ${duration}s ease-in-out`,
  },
};

const css = Object.fromEntries(Object.entries(transitionClasses).map(([className, styles]) => [`&.${className}`, styles]));


export const FadeTransition: FC<{ match: boolean, children: ReactNode }> = function FadeTransition({ match, children }) {
  const nodeRef = useRef(null);
  const history = useHistory();

  const [, setAnimationStateTrigger] = useState<number>(0);
  const animationStateRef = useRef<TransitionState>(match ? 'visible' : 'hidden');
  const animationTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  if(match) {
    if(animationStateRef.current === 'hidden') {
      if(history.action === 'POP') {
        animationStateRef.current = 'visible';
      } else {
        animationStateRef.current = 'willAppear';
        setTimeout(() => {
          animationStateRef.current = 'visible';
          setAnimationStateTrigger(Math.random());
        }, duration*1000);
      }
    } else if(animationStateRef.current === 'willDisappear') {
      if(animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
      animationStateRef.current = 'visible';
    } else if(animationStateRef.current === 'willAppear') {
      // do nothing
    } else if(animationStateRef.current === 'visible') {
      // do nothing
    } else {
      throw new Error(`Unexpected animation state: ${animationStateRef.current}, match: ${match}`);
    }
  } else {
    if(animationStateRef.current === 'visible') {
      if(history.action === 'POP') {
        animationStateRef.current = 'hidden';
      } else {
        animationStateRef.current = 'willDisappear';
        animationTimeoutRef.current = setTimeout(() => {
          animationStateRef.current = 'hidden';
          setAnimationStateTrigger(Math.random());
        }, duration*1000);
      }
    } else if(animationStateRef.current === 'willAppear') {
      if(animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
      animationStateRef.current = 'hidden';
    } else if(animationStateRef.current === 'willDisappear') {
      // do nothing
    } else if(animationStateRef.current === 'hidden') {
      // do nothing
    } else {
      throw new Error(`Unexpected animation state: ${animationStateRef.current}, match: ${match}`);
    }
  }

  const isVisible = animationStateRef.current === 'visible';

  return (
    <Box
      ref={nodeRef}
      className={clsx({ [animationStateRef.current]: true })}
      sx={css}
    >
      <Box sx={{
        height: '100%',
        '& textarea, & input': {
          visibility: isVisible ? 'inherit' : 'hidden',
          contentVisibility: isVisible ? 'inherit' : 'hidden',
        }
      }}>
        <div>
          {children}
        </div>
      </Box>
    </Box>
  );
}
