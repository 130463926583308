import { SxProps } from '@mui/material';
import { FC } from 'react';
import { Avatar, AvatarProps } from './Avatar';
import { AvatarGroup } from './AvatarGroup';


export const AvatarList: FC<{ contacts: AvatarProps["contact"][], size: AvatarProps["size"], max?: number, sx?: SxProps, avatarSx?: SxProps }> = function AvatarList({ contacts, size='medium', max=5, sx={}, avatarSx={}, ...rest }) {

  return (
    <AvatarGroup max={max} spacing={5} size={size} sx={{ display: 'inline-flex', pl: '5px', ...sx }} {...rest} >
      {contacts.filter(Boolean).map(c => typeof c === 'string' ? c : c?._id).filter((v, i, a) => a.indexOf(v) === i).map(id =>
        <Avatar size={size} key={id} contact={id} sx={avatarSx} />
      )}
    </AvatarGroup>
  );
}
