import { useCallback, useState, useRef, useEffect, FC, SyntheticEvent, Fragment, ReactNode } from 'react';
import { Box, Divider, Modal, NoSsr, PaletteColor, Tooltip, Typography, alpha, useTheme } from '@mui/material';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

import { Comment as CommentType, Share } from '../generated/graphql';
import { formatAge } from '../utils/time';
import { CommentInput, CommentInputProps } from './CommentInput';
import { Comment, CommentProps } from './Comment';
import { MentionAutocomplete } from './MentionAutocomplete';
import { TitleAutocomplete } from './TitleAutocomplete';
import { Loading } from './Loading';


export type ChatOverlayProps = {
  share?: Pick<Share, '_id' | 'createdAt'> & CommentProps['share'] & CommentInputProps['share'],
  comments: Array<
    Pick<CommentType, 'createdAt'> & CommentProps['comment'] & CommentInputProps['comments'][number]
  >,
  watchStateId?: string,
  onClose: (e: SyntheticEvent) => void,
  focusInput?: boolean,
  contextColor: PaletteColor,
  open?: boolean,
  loading?: boolean,
  children: ReactNode,
}


export const ChatOverlay: FC<ChatOverlayProps> = function ChatOverlay({ share, comments, watchStateId, onClose, focusInput=false, contextColor, open=true, loading=false, children }) {

  const theme = useTheme();

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    window.requestAnimationFrame(() => setIsMounted(true));
  }, []);

  const [atName, setAtName] = useState('');
  const [plusTitle, setPlusTitle] = useState('');
  const [autocompleteWord, setAutocompleteWord] = useState<string | undefined>();

  const scrollRef = useRef<HTMLDivElement>();

  const setCurrentWord = useCallback((word: string) => {
    if(word.length >= 2 && word[0] === '@') {
      setAtName(word);
    } else {
      setAtName('');
    }
    if(word.length >= 2 && word[0] === '+') {
      setPlusTitle(word);
    } else {
      setPlusTitle('');
    }
  }, []);

  const stopPropagation = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
  }, []);

  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  }, []);

  useEffect(() => {
    scrollToBottom();
    if (Capacitor.isNativePlatform()) {
      const eventHandlePromise = Keyboard.addListener('keyboardDidShow', scrollToBottom).catch(e => console.error('Keyboard.keyboardDidShow event', e));
      return () => {
        eventHandlePromise.then((eventHandle) => eventHandle && eventHandle.remove()).catch(e => console.error('remove Keyboard.keyboardDidShow event', e));
      }
    }
  }, [scrollToBottom]);

  // initialize prevAge to post createdAt so that the first age will be skipped if it is the same as the post
  let prevAge = formatAge(new Date(share?.createdAt), 'long');

  return (
    <>
      <Modal
        open={open}
        onClick={onClose}
        disableEnforceFocus={true}
        BackdropProps={{
          sx: {
            background: 'rgba(204, 204, 204, 0.2)',
            backdropFilter: 'blur(10px)',
          },
        }}
        sx={{
          display: open ? 'flex' : 'none',
          justifyContent: 'center',
          width: '100%',
          '&.MuiModal-hidden': {
            display: 'none',
          },
        }}
        onClose={onClose}
        keepMounted={true}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 'sm',
            width: '100%',
            pt: 'var(--sat)',
            height: '100%',
            overflowY: 'scroll',
            pb: '116px',
            opacity: isMounted ? 1 : 0,
            transition: 'opacity 0.18s cubic-bezier(0.4, 0, 0.2, 1)',

            '&:before': {
              content: '""',
              width: '100%',
              height: '64px',
              position: 'fixed',
              left: 0,
              top: 0,
              zIndex: 2,
              background: `linear-gradient(${theme.palette.background.paper} 0px, ${theme.palette.background.paper} 8px, ${alpha(theme.palette.background.paper, 0.7)} 24px, ${alpha(theme.palette.background.paper, 0.001)} 48px)`,
              opacity: isMounted ? 1 : 0,
              transition: 'opacity 1.0s cubic-bezier(0.4, 0, 0.2, 1)',
            },
          }}
        >
          {open &&
            <>
              <div onClick={stopPropagation}>
                {atName && !autocompleteWord &&
                  <MentionAutocomplete atName={atName} setAutocompleteWord={setAutocompleteWord} sx={{ zIndex: 2, position: 'absolute', pb: 4, pt: 3 }} />
                }
                {plusTitle && !autocompleteWord &&
                  <TitleAutocomplete plusTitle={plusTitle} setAutocompleteWord={setAutocompleteWord} sx={{ zIndex: 2, position: 'absolute', pb: 4, pt: 3 }} />
                }
              </div>

              {children}

              {comments && comments.length > 0 &&
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {comments.map((comment, idx) => {
                    const age = formatAge(new Date(comment.createdAt), 'long');
                    let ageComponent = null;
                    if(age !== prevAge) {
                      prevAge = age;
                      ageComponent = (
                        <Box sx={{ display: 'flex', pt: 1, pb: 0, alignSelf: 'center', gap: 2, width: '100%', px: 4 }}>
                          <Divider sx={{ alignSelf: 'center', flex: '1 1 auto', color: 'grey.50' }} />
                          <Tooltip title={<NoSsr>{new Date(comment.createdAt).toLocaleString()}</NoSsr>}>
                            <Typography variant='body2' color='text.secondary'>
                              {age}
                            </Typography>
                          </Tooltip>
                          <Divider sx={{ alignSelf: 'center', flex: '1 1 auto', color: 'grey.50' }} />
                        </Box>
                      );
                    }
                    return (
                      <Fragment key={comment._id}>
                        {ageComponent}
                        <Comment share={share} comment={comment} key={comment._id} contextColor={contextColor.background || contextColor} elevate={true} showAuthor={comment.author && (comments[idx-1] && comments[idx-1].author?._id === comment.author._id) ? false : true} onClick={stopPropagation} />
                      </Fragment>
                    );
                  })}
                </Box>
              }

              {(!comments || comments.length === 0) && loading &&
                <Loading />
              }
            </>
          }

          <Box ref={scrollRef} />

        </Box>
      </Modal>

      {/* We have to keep this out of the modal because position:fixed don't work with css transform */}
      {open && <CommentInput
        share={share}
        comments={comments}
        watchStateId={watchStateId}
        focusInput={focusInput}
        setCurrentWord={setCurrentWord}
        autocompleteWord={autocompleteWord}
        setAutocompleteWord={setAutocompleteWord}
        contextColor={contextColor}
        onClose={onClose}
      />}
    </>
  );
}
