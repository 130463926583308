import { FC, useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';

import { isHydrating, isServer } from '@/utils/detect';


export type LazyRenderProps = {
  children: React.ReactNode;
  height?: number;
  offset?: number;
  resize?: boolean;
  unmountIfInvisible?: boolean;
  overflow?: boolean;
  scrollContainerRef: React.RefObject<HTMLElement> | undefined;
};


export const LazyRender: FC<LazyRenderProps> = function LazyRender({ children, height=100, offset=1000, resize=true, unmountIfInvisible=true, overflow=true, scrollContainerRef }) {

  const [renderAll, setRenderAll] = useState(isServer || isHydrating ? true : false);

  useEffect(() => {
    if(renderAll) {
      setTimeout(() => {
        setRenderAll(false);
      }, 1000);
    }
  }, [renderAll]);

  return (
    <LazyLoad
      height={height}
      offset={offset}
      resize={resize}
      unmountIfInvisible={unmountIfInvisible}
      overflow={overflow}
      scrollContainerRef={scrollContainerRef}
      forceVisible={renderAll}
    >
      {children}
    </LazyLoad>
  );
}
